<template>
  <div class="case">
    <section class="banner1">
      <img src="../../images/home-banner.webp" />
    </section>
    <section class="tags">
      <div
        ref="tag"
        class="tag"
        :class="{ active: activeTabMoreIndexs[index].active }"
        v-for="(tag, index) in tags"
        :key="tag.title.id"
      >
        <div class="titleBox">
          <div class="title">{{ tag.title.nickname }}</div>
        </div>
        <div class="subTag">
          <span
            class="title"
            :class="{
              active: subTag.active,
            }"
            v-for="(subTag, subIndex) in tag.disData"
            :key="subTag.id"
            @click="onClickSubTag(index, subIndex)"
            >{{ subTag.nickname }}</span
          >
        </div>
        <div class="actionBox">
          <div class="action" @click="onClickTagMore(index)">
            <span class="text">更多</span>
            <img class="icon" src="../../images/moreArrow.png" />
          </div>
        </div>
      </div>
    </section>
    <div class="filter">
      <div class="tabs">
        <div
          class="tab"
          :class="{ active: activeTabIndex == index }"
          v-for="(item, index) in tabs"
          :key="index"
          @click="onClickTab(index)"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="search">
        <el-input
          placeholder="搜索更多"
          prefix-icon="el-icon-search"
          v-model="search"
          @blur="onSearch"
        >
        </el-input>
      </div>
    </div>
    <div class="content" v-loading="loading">
      <div class="caseBox">
        <div class="case" v-for="item in caseList" :key="item.works_id">
          <m-case :item="item" />
        </div>
      </div>
    </div>
    <div class="showMore">
      <div class="btn" v-if="hasData" @click="showMore">查看更多</div>
      <div v-else class="noMore">
        <span class="noMoreText">我是有底线的</span>
      </div>
    </div>
  </div>
</template>

<script>
import mCase from '@/components/mCase'
import { caseLabel, caseList } from '@/api/common'
let flag = {
  all: '',
  second_category_id: 1,
  plate: 2,
  search: 3,
}

export default {
  components: {
    mCase,
  },
  data() {
    return {
      navId: '',

      tags: [],
      second_category_id: '',

      activeTabMoreIndexs: [],
      tabs: [
        {
          label: '所有',
          name: '',
        },
        {
          label: '人气爆款',
          name: 'index_recommend',
        },
        {
          label: '网站精选',
          name: 'newest_recommend',
        },
      ],
      activeTabIndex: 0,
      search: '',

      caseList: [],
      page: 1,
      hasData: true,

      loading: true,

      reqType: flag.all,
    }
  },
  created() {
    this.navId = this.$route.query.navId
  },
  mounted() {
    this.getCaseLabel()
    this.getCaseList({ type: 'refresh' })
  },
  methods: {
    async getCaseList({ type = 'refresh' }) {
      try {
        let res = await caseList({
          category_id: this.navId,
          page: this.page,
          plate:
            this.reqType === flag.plate
              ? this.tabs[this.activeTabIndex].name
              : '',
          second_category_id:
            this.reqType === flag.second_category_id
              ? this.second_category_id
              : '',
          search: this.reqType === flag.search ? this.search : '',
        })
        setTimeout(() => {
          this.loading = false
        }, 400)
        let list = res.data.list
        if (list && list.length > 0) {
          if (type == 'refresh') {
            this.caseList = [...list]
          } else if (type == 'more') {
            this.caseList = [...this.caseList, ...list]
          }
        } else {
          this.hasData = false

          if (this.page == 1) {
            this.caseList = []
          }
          this.$message('没有更多数据了')
        }
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },
    async getCaseLabel() {
      let { data } = await caseLabel()
      // 最后一个为导航菜单，不显示
      data.pop()
      data.forEach((item) => {
        let { disData } = item
        disData.forEach((subItem) => {
          subItem.active = false
        })
        disData.unshift({
          active: true,
          id: '',
          nickname: '全部',
        })
      })
      this.activeTabMoreIndexs = data.map(() => {
        return {
          active: false,
        }
      })
      this.tags = data
    },
    onClickSubTag(index, subIndex) {
      this.tags[index].disData.find((item) => item.active).active = false
      this.tags[index].disData[subIndex].active = !this.tags[index].disData[
        subIndex
      ].active
      this.loading = true
      this.page = 1
      this.hasData = true
      this.reqType = flag.second_category_id
      this.second_category_id = ''
      for (let i = 0; i < this.tags.length; i++) {
        let item = this.tags[i]
        for (let j = 0; j < item.disData.length; j++) {
          let subItem = item.disData[j]
          if (subItem.active && j > 0) {
            this.second_category_id += `|${item.title.py},${subItem.id}`
            break
          }
        }
      }
      this.getCaseList({ type: 'refresh' })
    },
    onClickTab(index) {
      this.loading = true
      this.activeTabIndex = index
      this.page = 1
      this.hasData = true
      this.reqType = flag.plate
      this.getCaseList({ type: 'refresh' })
    },
    onClickTagMore(index) {
      this.activeTabMoreIndexs[index].active = !this.activeTabMoreIndexs[index]
        .active
    },
    onSearch() {
      this.reqType = flag.search
      this.getCaseList({ type: 'refresh' })
    },
    showMore() {
      if (this.hasData) {
        this.loading = true
        this.page++
        this.getCaseList({ type: 'more' })
      } else {
        this.$message('没有更多数据了')
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../styles/var.scss';

.case {
  .banner1 {
    width: 100%;
    overflow: hidden;
    img {
      transition: all 0.5s;
    }
    img:hover {
      transform: scale(1.05);
    }
  }
  .tags {
    width: 905px;
    margin: 30px auto 5px;
    .tag {
      display: flex;
      height: 40px;
      overflow: hidden;
      transition: all 0.5s;
      &.active {
        height: auto;
      }
      .titleBox {
        min-width: 150px;
        > .title {
          min-width: 70px;
          display: inline-block;
          font-size: 16px;
          color: #929198;
          background-color: #f4f4f5;
          border: 1px solid #909090;
          border-radius: 4px;
          padding: 4px 10px;
          line-height: 1;
        }
      }
      .subTag {
        flex: 1;
        text-align: left;
        > .title {
          display: inline-block;
          font-size: 15px;
          color: #828282;
          border-radius: 8px;
          margin-right: 30px;
          margin-bottom: 15px;
          line-height: 25px;
          cursor: pointer;
          padding: 0 6px;

          &.active {
            background-color: #f5c030;
            color: #000;
          }
        }
      }
      .actionBox {
        .action {
          display: flex;
          align-items: center;
          line-height: 25px;
          cursor: pointer;
          .text {
            font-size: 15px;
            color: #828282;
          }
          .icon {
            display: inline-block;
            width: 11px;
            height: 7px;
            margin-left: 8px;
          }
        }
      }
    }
  }
  .filter {
    width: 1000px;
    margin: 0 auto 17px;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;

    .tabs {
      display: flex;
      align-items: center;
      .tab {
        font-size: 16px;
        color: #383838;
        line-height: 28px;
        margin-right: 30px;
        position: relative;
        cursor: pointer;
        &.active {
          color: $saffron;
          &::after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            width: 22px;
            height: 3px;
            background: $saffron;
            border-radius: 2px;
          }
        }
      }
    }
    .search {
      & /deep/ .el-input__inner {
        border-radius: 20px;
        height: 32px;
        line-height: 32px;
        width: 270px;
      }
      & /deep/ .el-input__icon {
        line-height: 32px;
      }
    }
  }
  .content {
    background-color: #f4f5f9;
    .caseBox {
      box-sizing: border-box;
      width: 1340px;
      display: flex;
      flex-wrap: wrap;
      padding: 35px 0 15px 0;
      margin: 0 auto;
      .case {
        margin: 0 5px 10px;
        background: #fff;
        padding: 5px;
      }
    }
  }
  .showMore {
    display: flex;
    justify-content: center;
    padding-bottom: 48px;
    background-color: #f4f5f9;
    .btn {
      background-color: $saffron;
      font-size: 18px;
      font-weight: bold;
      color: #444446;
      padding: 14px 24px;
      cursor: pointer;
    }
    .noMore {
      .noMoreText {
        display: inline-block;
        font-size: 18px;
        color: #000;
        position: relative;
        &::before,
        &::after {
          display: block;
          content: '';
          position: absolute;
          width: 60px;
          height: 2px;
          background-color: #444445;
          top: 50%;
          transform: translateY(-50%);
        }
        &::before {
          left: -65px;
        }
        &::after {
          right: -65px;
        }
      }
    }
  }
}
</style>
